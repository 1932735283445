// Elgiganten
import { ReactComponent as ElgigantenLogo } from "../../ELGIGANTEN-LOGS/assets/ELGIGANTEN_LOGO.svg";
import { ReactComponent as MetaCornerLeft } from "../assets/elgiganten/Corner_left.svg";
import { ReactComponent as MetaCornerRight } from "../assets/elgiganten/Corner_right.svg";
import { ReactComponent as ScoreJewl } from "../assets/elgiganten/shape.svg";
import { ReactComponent as CenterPlayer1 } from "../assets/elgiganten/player_1_kill_box.svg";
import { ReactComponent as CenterPlayer2 } from "../assets/elgiganten/player_2_kill_box.svg";

// TCL
import { ReactComponent as MetaCornerLeftTCL } from "../../TCL-LOGS/assets/Corner_left.svg";
import { ReactComponent as MetaCornerRightTCL } from "../../TCL-LOGS/assets/Corner_right.svg";
import { ReactComponent as TclLogo } from "../../TCL-LOGS/assets/TCL_LOGO.svg";
import { ReactComponent as ScoreJewlTCL } from "../../TCL-LOGS/assets/shape.svg";
import { ReactComponent as CenterPlayer1TCL } from "../assets/tcl/pink_cube-left.svg";
import { ReactComponent as CenterPlayer2TCL } from "../assets/tcl/blue_cube-right.svg";

export interface ThemeProps {
  logo: React.ReactNode; // JSX element or React component
  cornerLeft: React.ReactNode; // JSX element or React component
  cornerRight: React.ReactNode; // JSX element or React component
  scoreJewl: React.ReactNode; // JSX element or React component
  centerPlayer1: React.ReactNode; // JSX element or React component
  centerPlayer2: React.ReactNode; // JSX element or React component
  player1Name: string;
  player2Name: string;
  themeStyleValue: string;
}

// Define the main type for the entire theme object
interface Themes {
  ELGIGANTEN: ThemeProps;
  TCL: ThemeProps;
  PCSPECIALIST: ThemeProps;
}

export const theme: Themes = {
  ELGIGANTEN: {
    logo: <ElgigantenLogo width={342} height={80} />,
    cornerLeft: <MetaCornerLeft width={52} height={52} />,
    cornerRight: <MetaCornerRight width={98} height={36} />,
    scoreJewl: <ScoreJewl width={250} height={194} />,
    centerPlayer1: <CenterPlayer1 width={250} />,
    centerPlayer2: <CenterPlayer2 width={250} />,
    player1Name: "Player 1",
    player2Name: "Player 2",
    themeStyleValue: "elgiganten",
  },
  TCL: {
    logo: <TclLogo width={342} height={80} />,
    cornerLeft: <MetaCornerLeftTCL width={52} height={52} />,
    cornerRight: <MetaCornerRightTCL width={98} height={36} />,
    scoreJewl: <ScoreJewlTCL width={250} height={194} />,
    centerPlayer1: <CenterPlayer1TCL width={250} />,
    centerPlayer2: <CenterPlayer2TCL width={250} />,
    player1Name: "Hero",
    player2Name: "Villain",
    themeStyleValue: "tcl",
  },
  PCSPECIALIST: {
    logo: <ElgigantenLogo width={342} height={80} />,
    cornerLeft: <MetaCornerLeft width={52} height={52} />,
    cornerRight: <MetaCornerRight width={98} height={36} />,
    scoreJewl: <ScoreJewl width={250} height={194} />,
    centerPlayer1: "",
    centerPlayer2: "",
    player1Name: "",
    player2Name: "",
    themeStyleValue: "",
  },
};
