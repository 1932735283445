import { useLeaderboardData } from "@/hooks/useUserDataHook";
import Container from "@/components/Container/Container";
import Grid from "@/components/Grid/Grid";
import Col from "@/components/Col/Col";
import Feed from "@/components/Feed/Feed";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import confetti from "canvas-confetti";
import { FC, useEffect } from "react";
import { theme } from "./themeData/csThemeData";

export const CS_ScoreBoard: FC<{
  client: "TCL" | "ELGIGANTEN" | "PCSPECIALIST";
}> = ({ client }) => {
  const { usersData, logs } = useLeaderboardData(client, "ELGIGANTEN");

  // Player scores
  const player1Score = usersData?.player1?.kills || 0;
  const player2Score = usersData?.player2?.kills || 0;

  useEffect(() => {
    if (player1Score !== 0) {
      confetti({
        particleCount: 15,
        spread: 40,
        origin: { y: 0.2, x: 0.5 },
        angle: 180,
      });
    }
  }, [player1Score]);

  useEffect(() => {
    if (player2Score !== 0) {
      confetti({
        particleCount: 15,
        spread: 40,
        origin: { y: 0.2, x: 0.5 },
        angle: 0,
      });
    }
  }, [player2Score]);

  // Get custom theme and styling based on client
  const themeData = theme[client];

  // Set document title
  useDocumentTitle("ELGIGANTEN");

  return (
    <div className={themeData.themeStyleValue}>
      <div className="cs">
        <Container>
          <Grid columns={3}>
            <Col start={2} end={3}>
              <div className="cs__brand">{themeData.logo}</div>
            </Col>
          </Grid>
          <div className="cs__header">
            <div id="shapeTopLeft">{themeData.cornerLeft}</div>
            <div id="shapeBottomRight">{themeData.cornerRight}</div>
            <div id="shapeCenter">{themeData.scoreJewl}</div>
            <div className="cs__content">
              <Grid columns={6}>
                <Col start={1} end={4}>
                  <div className="cs__player">
                    <h2>{themeData.player1Name}</h2>
                  </div>
                </Col>
                <Col start={4} end={7}>
                  <div className="cs__player">
                    <h2>{themeData.player2Name}</h2>
                  </div>
                </Col>
              </Grid>
            </div>
            <div className="cs__scores">
              <Grid columns={3}>
                <Col start={2} end={3}>
                  <div className="cs__score">
                    <div className="scores">
                      <div className="scores__item">{player1Score}</div>
                      <div className="scores__separator">:</div>
                      <div className="scores__item">{player2Score}</div>
                    </div>
                  </div>
                </Col>
              </Grid>
            </div>
          </div>
          <Feed logs={logs} themeData={themeData} />
        </Container>
      </div>
    </div>
  );
};
