import { Events } from "@/hooks/useUserDataHook";
import { cs2Weapons } from "@/consts/cs2Weapons";
import { FeedProps } from "./Feed.types";
// import { ReactComponent as CenterPlayer1 } from "../../clients/CS/assets/player_1_kill_box.svg";
// import { ReactComponent as CenterPlayer2 } from "../../clients/CS/assets/player_2_kill_box.svg";
import { getTime } from "@/helperFunctions/formatMsToTime";

const Feed: React.FC<FeedProps> = ({ logs, themeData }) => {
  const latestFourLogs = logs?.slice(0, 4);

  if (!latestFourLogs) {
    return null;
  }

  return (
    <div className="event">
      {latestFourLogs.map((data: Events, index) => {
        const player = data.player === "Player 1" ? 1 : 0;
        const key = index;

        const isPlayer1 = player === 1;
        const alignment = isPlayer1 ? "align-left" : "align-right";

        return (
          <div key={key} className={`event__item ${alignment}`}>
            <div className={`event__content ${alignment}`}>
              <div className="event__body">
                <div className="event__weapon">
                  {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                </div>
              </div>
            </div>
            <div className={`event__shape ${alignment}`}>
              {isPlayer1 ? themeData.centerPlayer1 : themeData.centerPlayer2}
            </div>
            <div className={`event__type ${alignment}`}>
              {data.wasHeadshot ? "Headshot" : "Kill"}
            </div>

            <div className={`event__timestamp ${alignment}`}>
              <time>{getTime(data.timestamp)}</time>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Feed;
