import { TCL_ScoreBoard } from "./clients/TCL/Scoreboard";
import "./globalStyles/index.scss";
import { PCSPECIALIST_ScoreBoard } from "./clients/PCSPECIALIST/Scoreboard";
import { TCLLOGS_ScoreBoard } from "./clients/TCL-LOGS/Scoreboard";
import { ELGIGANTEN_ScoreBoard } from "./clients/ELGIGANTEN-LOGS/Scoreboard";
import { CS_ScoreBoard } from "./clients/CS/Scoreboard";
function App() {
  const client = window.location.pathname;
  console.log(client.replace("/", " "));

  type ScoreBoardMap = {
    [key: string]: JSX.Element;
  };

  const scoreBoardToRender = {
    "/tcl": <TCL_ScoreBoard />,
    "/tcl-logs": <CS_ScoreBoard client={"TCL"} />,
    "/pcspecialist": <PCSPECIALIST_ScoreBoard />,
    "/elgiganten": <CS_ScoreBoard client={"ELGIGANTEN"} />,
    // "/cs": <CS_ScoreBoard />,
  } as ScoreBoardMap;

  if (client.replace("/", " ").trim() === "") {
    return (
      <div className="app">
        <h1>No client found, /clientName missing</h1>
      </div>
    );
  }

  return (
    <div className="app">
      {scoreBoardToRender[client] || "no scoreboard found with this name"}
    </div>
  );
}

export default App;
